import { Layout, List, Alert, Input, Table, Tag, Button, Typography, Col, Badge, Switch, Spin, Form, Row} from 'antd';
import { Link, useNavigation, useLoaderData } from "react-router-dom";
import { exportToExcel } from "../utils";
import React, {useState, useEffect, useRef, InputRef} from "react";
import { fetchThreadsData } from "../../endpoints/fetchThreadsData";
import {currencyFormatter, volumeFormatter} from "../utils";
import Loading from "../Loading";
import {useSearchParams, useOutletContext} from 'react-router-dom';
import {EmailContent} from './EmailContent';
import dayjs from "dayjs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import Workflow from './Workflow'
import { error } from 'highcharts';

const { Header, Sider, Content } = Layout;
const { Text } = Typography;

const colorPrimary = '#4fad96';

const ThreadsExplorer = (props) => {
    const {userData, setHasError} = props;
    const {accessToken} = userData;

    const [forReviewCount, setForReviewCount, products, setProducts] = useOutletContext();

    const [dataSource, setDataSource] = useState(null);
    const [fromEmails, setFromEmails] = useState([]);
    const [fullScreen, setFullScreen] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [pollingTaskId, setPollingTaskId] = useState(null);
    const [threadMessages, setThreadMessages] = useState([]);
    const [selectedThread, setSelectedThread] = useState(null);
    const [triggerEffect, setTriggerEffect] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [generatedOrder, setGeneratedOrder] = useState(null);
    const [workflowState, setWorkflowState] = useState({
        products: [],
        generatedOrder: null,
        selectedMessage: null,
        selectedThread: null,
    });
    const [showNewTasksOnly, setShowNewTasksOnly] = useState(true);
    const [workflowVisible, setWorkflowVisible] = useState(false);
    const [workflowConfig, setWorkflowConfig] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(null);
    const [showFailedAlert, setShowFailedAlert] = useState(null);

    const abortControllerRef = useRef(null);

    useEffect(() => {
        setWorkflowState({
            products: products,
            generatedOrder: generatedOrder,
            selectedMessage: selectedMessage,
            selectedThread: selectedThread,
        });
    }, [products, generatedOrder, selectedMessage, selectedThread]);

    useEffect(() => {
        if (selectedThread === null && dataSource) {
            setSelectedThread(dataSource.threads[0])
        }
    }, [selectedThread, dataSource]);

    useEffect(() => {
        if (!threadMessages || threadMessages.length === 0) return;

        // if request comes back as attaching to workflow is started, make sure messages get set
        if (!selectedMessage && workflowConfig === 'attach') setSelectedMessage({...threadMessages[0], entity_type: 'email'});
    }, [threadMessages, workflowConfig])

    useEffect(() => {
        if (showSuccessAlert) {
            const timer = setTimeout(() => {
                setShowSuccessAlert(null);
            }, 5000);
            return () => clearTimeout(timer);
        }
        if (showFailedAlert) {
            const timer = setTimeout(() => {
                setShowFailedAlert(null);
            }, 60000);
            return () => clearTimeout(timer);
        }
    }, [showSuccessAlert, showFailedAlert]);

    // Function to start the create order workflow
    const startCreateOrderWorkflow = async (forTrainingData = false) => {
        const currentSelectedMessage = {...selectedMessage};
        if (forTrainingData)
            setWorkflowConfig('training');
        else
            setWorkflowConfig('save_order');

        setWorkflowVisible(true);

        // check localstorage for fullscreen prference
        const savedFullScreen = JSON.parse(localStorage.getItem('pantry-fullScreen'));
        setFullScreen(savedFullScreen || false); // Default to false if nothing is stored
        
        // only fetch data if it's a specififc selection, otherwise generated order was fetched as a polling task on thread select
        if (currentSelectedMessage && Object.keys(currentSelectedMessage).length > 0) {

            setGeneratedOrder(null); // show loading screen
            try {
                const result = await fetchData({'info_type': 'generate_order', 'entity': currentSelectedMessage, 'trainingData': forTrainingData}, true);
                
                if (result !== 'aborted' && result['error'] !== null && result['error'] !== undefined) {
                    setGeneratedOrder({'error': result['error']});
                }
        
                setPollingTaskId(result['task_id']);
            } catch (e) {
                setGeneratedOrder({'error': e});
            }
        }
    };

    const startAttachWorkflow = async () => {
        setWorkflowConfig('attach');
        setWorkflowVisible(true);
    };

    const handleWorkflowFinish = async (formState, dismiss) => {
        const currentSelectedMessage = selectedMessage ? {...selectedMessage} : null;

        // filter out the data in all_candidates, it adds a ton to the dynamodb payload
        const currentFormState = {
            ...formState, 
            'PRODUCT_LINE_ITEMS': [
                ...(formState['PRODUCT_LINE_ITEMS'] ?? []).map((li) => {
                    return [li[0], {...li[1], 'all_candidates': []}]
                })
            ],
            'USER_SELECTED_PRODUCT_LINE_ITEMS': [
                ...formState['USER_SELECTED_PRODUCT_LINE_ITEMS'].map((li) => {
                    return {...li, 'all_candidates': []}
                })
            ]
        }

        setWorkflowVisible(false);
        setGeneratedOrder(null);
        setFullScreen(false);
        setWorkflowConfig(null);

        const currentThread = selectedThread;

        let createdFromEntity = currentSelectedMessage ? currentSelectedMessage : {...threadMessages[0], entity_type: 'email'};        
        createdFromEntity = {...createdFromEntity};
        delete createdFromEntity.body; // Removing the 'body' property if it exists for network latency reasons

        // default to threadMessages[0] if selectedMessage has nothing. this is for associations to thread for one-click orders
        const result = await fetchData({'info_type': workflowConfig, 'created_from_entity': createdFromEntity, 'entity': currentFormState}, false);

        if (result.error) {
            setShowFailedAlert(result.message);
        } else if (result.order_id) {
            if (dismiss) {
                handleDismissClick(currentThread);
            }
            setShowSuccessAlert({'order_id': result.order_id});
        }
    };

    const toggleFullScreen = () => {
        const newFullScreen = !fullScreen;
        localStorage.setItem('pantry-fullScreen', JSON.stringify(newFullScreen));
        setFullScreen(newFullScreen);
    };

    const fetchData = async (info_description, raise_anyway=false) => {
        // If there's an ongoing thr
        // if (abortControllerRef.current && dataSource !== null) {
        //     abortControllerRef.current.abort();
        // }

        // Create a new AbortController
        abortControllerRef.current = new AbortController();

        let data;
        let wasAborted;
        try {
            data = await fetchThreadsData(
                accessToken,
                {...info_description},
                { signal: abortControllerRef.current.signal }
            );
        } catch (e) {
            if (e.name === 'AbortError') {
                console.log('Fetch aborted');
                wasAborted = true;
                return 'aborted';
            } else {
                wasAborted = false;
                setHasError(e);
            }

            if (raise_anyway) throw e;
            console.log('err')
            console.log(e);
        }
        if (userData.email && userData.email.includes('@pantry'))
            console.log(data)

        return data;
    }

    useEffect(() => {
        if (dataSource === null) {
            fetchData({'info_type': 'threads'}).then(data => {
                setFromEmails(data?.emails);
                setDataSource(data);
            });
        }
    }, []);

    useEffect(() => {
        // Poll backend for async processing task to be done

        const maxPollingTime = 300000; // Maximum polling time of 5 mins
        const startTime = Date.now();
        let pollingInterval;

        const pollTask = async () => {
            if (pollingTaskId !== null && Date.now() - startTime < maxPollingTime) {
                try {
                    const task = await fetchData({'info_type': 'task_poll', 'task_id': pollingTaskId});
                    
                    if (task === 'aborted') {
                        setGeneratedOrder(null);
                        setPollingTaskId(null);
                    } else if (task['task'].error !== undefined && task['task'].error !== null) {
                        console.error('Task error:', task.error);
                        setGeneratedOrder({error: task['task'].error});
                        setPollingTaskId(null); // Stop polling on task error
                    } else if (task['task'].complete) {
                        // TODO: this is so gross..
                        setGeneratedOrder(task['task']['result']);
                        setPollingTaskId(null);
                    } else {
                        pollingInterval = setTimeout(pollTask, 1000); // Poll every second
                    }
                } catch (error) {
                    console.error('Error polling task:', error);
                    setGeneratedOrder({'error': error});
                    setPollingTaskId(null); // Stop polling on fetch error
                }
            } else {
                setPollingTaskId(null); // Stop polling if time exceeds maxPollingTime
            }
        };

        pollTask();

        return () => clearTimeout(pollingInterval); // Clear timeout on cleanup
    }, [pollingTaskId]);

    useEffect(() => {
        if (selectedThread) {
            const threadOnSelection = selectedThread.ThreadId;

            fetchData({'info_type': 'specific_thread', 'ThreadId': threadOnSelection}).then(messages => {
                if (selectedThread.ThreadId === threadOnSelection && messages !== 'aborted') {
                    // only set messages if the selected thread hasn't changed already
                    const sortedMessages = messages['messages'].sort((a, b) => new Date(b.datetime) - new Date(a.datetime));
                    setThreadMessages(sortedMessages);
                    
                    if (messages['action_task_id']) setPollingTaskId(messages['action_task_id']);
                    else {
                        setGeneratedOrder({"DOCUMENT_TYPE_CLASSIFICATION": "OTHER"});
                    }
                }
            });
        } else {
            setThreadMessages([]);
        }
    }, [selectedThread, triggerEffect]);

    const selectThread = (thread, clear) => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    
        setSelectedMessage(null);
        setGeneratedOrder(null);
        setPollingTaskId(null); // cancel any active polling
        if (clear) setThreadMessages(null);
        setSelectedThread(thread);
        setTriggerEffect(prev => !prev);
    }

    const handleWorkflowCancel = () => {
        setWorkflowVisible(false);
        setWorkflowConfig(null);
        setFullScreen(false);
        selectThread(selectedThread, false);
    };

    const handleMessageClick = (message, selectedType) => {
        if (!workflowVisible) {
            setGeneratedOrder(null);
            setSelectedMessage({...message, 'body': null, 'entity_type': selectedType});
        } else
            console.log('already in workflow, can not select new unless finish or cancel')
    }

    const handleDismissClick = (threadOverride) => {
        const thread = threadOverride ? threadOverride : selectedThread;

        const val = thread.isNew === "1" ? "0" : "1";
        const threadElement = document.getElementById(`thread-${thread.ThreadId}`);

        if (threadElement) {
          threadElement.classList.add("slide-out");
        }

        setTimeout(() => {
          // async update DB
          fetchData({ 'info_type': 'change_isnew', 'ThreadId': thread.ThreadId, 'value': val });

          // set to dismissed/undismissed locally
          setDataSource({
            ...dataSource,
            threads: dataSource.threads.filter((t) => t.ThreadId !== thread.ThreadId)
          });
          
          setSelectedThread(null);
          setSelectedMessage(null);
          setForReviewCount(val === "1" ? forReviewCount + 1 : forReviewCount - 1);
        }, 200); // Match the duration of the animation
    };

    const dedupThreads = (existingThreads, newThreads) => {
        const mergedThreads = [...existingThreads, ...(newThreads ? newThreads : [])];
        const uniqueThreads = [];
        const threadIds = new Set();
    
        for (const thread of mergedThreads) {
            if (!threadIds.has(thread.ThreadId)) {
                uniqueThreads.push(thread);
                threadIds.add(thread.ThreadId);
            }
        }
    
        return uniqueThreads;
    };

    const loadMore = () => {
        setLoadingMore(true);
        fetchData({ 'info_type': 'threads', 'paginationKey': dataSource.paginationKey, 'showDismissed': !showNewTasksOnly })
            .then((newData) => {
                if (newData) {
                    setDataSource({
                        ...dataSource,
                        threads: dedupThreads(dataSource.threads, newData.threads).sort((a, b) => dayjs(b.Timestamp).diff(dayjs(a.Timestamp))),
                        paginationKey: newData.paginationKey
                    });
                }
            })
            .finally(() => setLoadingMore(false));
    };

    if (dataSource === null) return <Loading style={{paddingTop: 200}}/>

    return (
        <>
        {showSuccessAlert !== null && (
            <Alert
                message={<Link to={`/orders/order?order_id=${showSuccessAlert.order_id}`}>Order Saved</Link>}
                type="success"
                showIcon
                style={{
                    position: 'absolute',
                    bottom: 10,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 1000,
                    width: '25%'
                }}
            />
        )}
        {showFailedAlert !== null && (
            <Alert
                message={showFailedAlert}
                type="error"
                showIcon
                closable
                style={{
                    position: 'absolute',
                    bottom: 10,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 1000,
                    width: '35%'
                }}
            />
        )}
        <div style={{ position: 'relative' }}>

        <Layout style={{ height: 'calc(100vh - 58px)', display: 'flex' }}>
        <Sider width={300} theme="light"  style={{ overflow: 'auto', maxHeight: '100vh', display: workflowVisible ? 'none' : 'block' }}>
            <div style={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                backgroundColor: '#fff',
                padding: '10px',
                borderBottom: '1px solid #f0f0f0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
            }}>
                {showNewTasksOnly ? 
                    <Typography.Text strong>Show Only Incomplete Tasks</Typography.Text> : 
                    <Typography.Text strong>Show Only Completed Tasks</Typography.Text>
                }
                <Switch checked={showNewTasksOnly} onChange={(checked) => {
                    setShowNewTasksOnly(checked);
                    setSelectedMessage(null);
                    setSelectedThread(null);
                    setDataSource(null);
                    fetchData({'info_type': 'threads', 'showDismissed': !checked}).then(data => {
                        setFromEmails(data['emails']);
                        setDataSource(data);
                    });
                }} />
            </div>
            <div style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 58px)' }}>
            <List
                itemLayout="horizontal"
                dataSource={showNewTasksOnly ? dataSource.threads.filter((t) => t.isNew === "1") : dataSource.threads}
                renderItem={(thread) => (
                    <List.Item
                        key={`${thread.ThreadId}-${thread.isNew}`}
                        id={`thread-${thread.ThreadId}`}
                        onClick={() => selectThread(thread, true)}
                        style={{
                            paddingLeft: '10px',
                            paddingRight: 5,
                            backgroundColor: selectedThread && selectedThread.ThreadId === thread.ThreadId ? '#e6f7ff' : 'transparent',
                            cursor: 'pointer'
                        }}
                    >
                        <List.Item.Meta
                            title={
                                <>
                                    <Row>
                                        <Col flex={'3px'}>
                                            {thread.isNew === '1' && (
                                                <Badge dot style={{ marginRight: 8 }} />
                                            )}
                                        </Col>
                                        <Col flex={'200px'} style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                            <Text strong>{thread.From ? (thread.From[0].name || thread.From[0].email) : ''}</Text>
                                        </Col>
                                        <Col flex='auto'/>
                                        <Col>
                                            <Text style={{ fontSize: '12px', paddingRight: 5}}>{dayjs(thread.Timestamp).format('MM/DD')}</Text>
                                        </Col>
                                    </Row>
                                </>
                            }
                            description={thread.Preview}
                        />
                    </List.Item>
                )}
                footer={
                    dataSource.paginationKey !== null && (
                        <div style={{ textAlign: 'center', margin: '10px 0' }}>
                            {loadingMore ? (
                                <Spin />
                            ) : (
                                <Button onClick={loadMore}>Load More</Button>
                            )}
                        </div>
                    )
                }           
            />
            </div>
        </Sider>
        {!fullScreen &&
            <Content style={{ flex: 1, overflow: 'auto' }}>
                {selectedThread ? (
                    <div>
                        {threadMessages && threadMessages.length > 0 ? threadMessages.map((message, index) => (
                            <EmailContent
                                key={index}
                                message={message}
                                onClick={handleMessageClick}
                                selectedEntity={selectedMessage}
                                lastDismissed={selectedThread.lastDismissed}
                            />
                        )) : <Loading style={{paddingTop: 200}}/>}
                    </div>
                ) : (
                    <Row>
                        <Col flex={'auto'}></Col>
                        <Col style={{paddingTop: 100}}>
                            <Text>No tasks to review! 🎉</Text>
                        </Col>
                        <Col flex='auto'></Col>
                    </Row>
                )}
            </Content>
            }
            <Sider width={fullScreen ? '100%' : (workflowVisible ? 600 : 200)} theme="light">
                <div style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 58px)'}}>
                {workflowVisible ? (
                        <Workflow
                            userData={userData}
                            workflowState={workflowState}
                            configType={workflowConfig}
                            onFinish={handleWorkflowFinish}
                            onCancel={handleWorkflowCancel}
                            fullscreen={fullScreen}
                            toggleFullScreen={toggleFullScreen}
                        />
                    ) : (
                        <div>
                            {selectedThread && (
                                <Button
                                    style={{ margin: '10px', display: 'block' }}
                                    onClick={() => handleDismissClick()}
                                >
                                    {selectedThread && selectedThread.isNew === "1" ? 'Dismiss Task' : 'Undismiss Task'}
                                </Button>
                            )}
                            {selectedThread && threadMessages !== null && threadMessages.filter((m) => m.web_order).length === 0 && (
                                <Button style={{ margin: '10px' }} onClick={startAttachWorkflow}>
                                    Attach to Order
                                </Button>
                            )}
                            {selectedThread && threadMessages !== null && threadMessages.filter((m) => m.web_order).length > 0 &&  (
                                <Button type="primary" style={{ margin: '10px' }} onClick={() => {}}>
                                    <Link to={{
                                        pathname: '/orders/order', 
                                        search: `?order_id=${threadMessages.filter((m) => m.web_order)[0].message_id}` 
                                    }} >View Order</Link>
                                </Button>
                            )}
                            {selectedThread && threadMessages !== null && threadMessages.filter((m) => m.web_order).length === 0 &&  (
                                <Button type="primary" style={{ margin: '10px' }} onClick={() => startCreateOrderWorkflow(false)}>
                                    <FontAwesomeIcon icon={faWandMagicSparkles} />&nbsp;Create Order
                                </Button>
                            )}
                            {selectedThread && selectedMessage && userData.email && userData.email.includes('arlo@pantry') && (
                                <Button style={{ margin: '10px' }} onClick={() => startCreateOrderWorkflow(true)}>
                                    <FontAwesomeIcon icon={faWandMagicSparkles} />&nbsp;Create Training Data
                                </Button>
                            )}
                            
                        </div>
                    )}
                </div>
            </Sider>
        </Layout>
        </div>
        </>
    );

}

export default ThreadsExplorer;
